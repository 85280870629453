import React from 'react';

type Props = {
  link: string;
};

const SocialLink: React.FC<Props> = ({ link, children }) => {
  return (
    <a
      target='_blank'
      href={link}
      className='p-2 hover:rotate-12 hover:text-yellow-500'
      rel='noreferrer'>
      {children}
    </a>
  );
}

export default SocialLink;
