import React from 'react';

import MenuImg from '../../images/menu.svg';

const scrollToSection = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, sectionId: string) => {
  let section = document.getElementById(sectionId);
  e.preventDefault();
  section && section.scrollIntoView({ behavior: 'smooth' });
}

const Header: React.FC = () => (
  <div className="bg-gradient-to-b from-yellow-900 to-stone-600 top-0 fixed w-screen z-10 px-2 sm:px-8 md:px-16 xl:px-30">
    <nav className="mx-5 sm:mx-20 md:mx-40">
      <div className="container md:flex md:items-center">
        <div className="flex justify-between items-center">
          <a href="/#" onClick={e => { scrollToSection(e, 'home') }}>
            <svg width="250" height="75" xmlns="http://www.w3.org/2000/svg">
              <g>
                <text transform="matrix(2.72649 0 0 2.11299 -37.9254 -29.905)" stroke="#000" fontWeight="bold" xmlSpace="preserve" textAnchor="start" fontSize="18" id="svg_1" y="38.6135" x="14.93102" strokeWidth="0" fill="#ffffff">&lt;louis/&gt;</text>
              </g>
            </svg>
          </a>
          <button className="px-3 rounded opacity-50 hover:opacity-75 md:hidden" id="navbar-toggle"
            onClick={() => {
              let collapse = document.querySelector('#navbar-collapse');
              collapse && collapse.classList.toggle('hidden');
              collapse && collapse.classList.toggle('flex');
            }}>
            <img className='h-8 w-8' src={MenuImg} alt='menu'/>
          </button>
        </div>
        <div className="hidden md:flex flex-col md:flex-row md:ml-auto mt-3 md:mt-0" id="navbar-collapse">
          <a href='/#'
            className="p-1 px-2 lg:px-4 md:mx-2 text-white rounded hover:underline hover:text-gray-300 transition-colors duration-300"
            onClick={e => { scrollToSection(e, 'home') }}>
              <b>HOME</b>
          </a>
          <a href="/#"
            className="p-1 px-2 lg:px-4 md:mx-2 text-white rounded hover:underline hover:text-gray-300 transition-colors duration-300"
            onClick={e => { scrollToSection(e, 'about') }}>
            <b>ABOUT</b>
          </a>
          <a href="/#"
            className="p-1 px-2 lg:px-4 md:mx-2 text-white rounded hover:underline hover:text-gray-300 transition-colors duration-300"
            onClick={e => { scrollToSection(e, 'experience') }}>
            <b>EXPERIENCE</b>
          </a>
          <a href="/#"
            className="p-1 px-2 lg:px-4 md:mx-2 text-white rounded hover:underline hover:text-gray-300 transition-colors duration-300"
            onClick={e => { scrollToSection(e, 'contact') }}>
            <b>CONTACT</b>
          </a>
        </div>
      </div>
    </nav>
  </div>
)

export default Header;