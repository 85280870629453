import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css';

import Home from './components/Home';

const TITLE = 'Louis Albert Apas - Software Engineer';

function App() {

  useEffect(() => {
    document.title = TITLE;
  }, []);

  return (
    <div className="App ">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />}>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
