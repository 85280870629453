import { motion } from 'framer-motion';

import ProfileImg from '../../images/profile.png';

// Icons
import { FiMail, FiLinkedin, FiGithub, FiFacebook, FiInstagram } from 'react-icons/fi';

// Components
import SocialLink from '../SocialLink';


const Hero = () => (

  <section id='home'>
    <div className="bg-hero-image bg-cover min-h-screen px-2 sm:px-8 md:px-16 xl:px-30">
      <div className='mt-30 pt-20 sm:pt-32'></div>
      <motion.div
        initial='hidden'
        animate='visible'
        variants={{hidden: {opacity: 0}, visible: {opacity: 1}}}
        className='md:w-auto py-2 md:py-20 mt-30 mx-5 sm:mx-20 md:mx-40 backdrop-brightness-75'>
        <div className="px-10 flex flex-wrap justify-center">
          <img
            src={ProfileImg} alt="profile-img"
            className="shadow rounded-full max-w-full w-auto border-4 border-yellow-900" />
        </div>
        <h1 className='text-white text-5xl mt-5 px-2 sm:px-10 font-bold'>
          LOUIS ALBERT APAS
        </h1>
        <p className='text-gray-200 text-xl px-2 sm:px-10 py-2'>
          Software Engineer at <a className='underline font-bold text-white hover:text-yellow-500' target='_blank' href='https://www.amcsgroup.com/' rel="noreferrer">AMCS Group</a>
        </p>
        <div className='flex justify-center items-center text-gray-200'>
          <SocialLink link='mailto:louisalbertapas25@gmail.com'>
            <FiMail className='h-6 w-6' />
          </SocialLink>
          <SocialLink link='https://www.linkedin.com/in/louisalbertapas'>
            <FiLinkedin className='h-6 w-6' />
          </SocialLink>
          <SocialLink link='https://github.com/louisalbertapas'>
            <FiGithub className='h-6 w-6' />
          </SocialLink>
          <SocialLink link='https://www.facebook.com/louisalbertapas'>
            <FiFacebook className='h-6 w-6' />
          </SocialLink>
          <SocialLink link='https://www.instagram.com/louisalbertapas1'>
            <FiInstagram className='h-6 w-6' />
          </SocialLink>
        </div>
      </motion.div>
    </div>
  </section>
)

export default Hero;
