import React from 'react';

// Images
import AmcsImg from '../../images/amcs.png';
import TellwellImg from '../../images/tellwell.png';
import EdlutionImg from '../../images/edlution.png';
import LexmarkImg from '../../images/lexmark.png';
import AllianceImg from '../../images/alliance.jpg';

// Icons
import {
  SiCsharp,
  SiDotnet,
  SiJavascript,
  SiJquery,
  SiBootstrap,
  SiMicrosoftsqlserver,
  SiMicrosoftazure,
  SiAzuredevops,
  SiAzurepipelines,
  SiGit,
  SiCplusplus,
  SiAngular,
  SiTypescript,
  SiAmazonaws,
  SiC,
  SiPython,
  SiGerrit,
  SiJenkins,
  SiMysql,
  SiPhp,
  SiGithub,
  SiAndroid,
  SiJava,
  SiHibernate,
  SiSpring,
  SiGithubactions,
  SiReact,
} from 'react-icons/si';

// Components
import WorkExperience from '../WorkExperience';

const workExperiences = [
  {
    jobTitle: 'Software Engineer',
    employer: 'AMCS Group',
    jobDuration: 'November 2023 - present',
    jobLogoImg: AmcsImg,
    jobLink: 'https://www.amcsgroup.com/',
    jobSummary: "Working as a Software Engineer belonging in a business unit that is in charge with creating and maintaining software solutions specifically used for transporting materials.",
    technologyStacks: [
      SiCsharp,
      SiTypescript,
      SiJavascript,
      SiDotnet,
      SiAngular,
      SiBootstrap,
      SiMicrosoftsqlserver,
      SiMicrosoftazure,
      SiAzuredevops,
      SiAzurepipelines,
      SiGit,
    ]
  },
  {
    jobTitle: 'Senior Software Developer',
    employer: 'Tellwell Talent',
    jobDuration: 'April 2021 - November 2023',
    jobLogoImg: TellwellImg,
    jobLink: 'https://www.tellwell.ca',
    jobSummary: "Worked as a Senior Software Developer who is responsible in developing new features and maintaining the organization's customized software suite for book publishing workflows.",
    technologyStacks: [
      SiCsharp,
      SiJavascript,
      SiDotnet,
      SiJquery,
      SiBootstrap,
      SiReact,
      SiMicrosoftsqlserver,
      SiMicrosoftazure,
      SiAzuredevops,
      SiAzurepipelines,
      SiGithub,
      SiGithubactions,
      SiGit,
    ]
  },
  {
    jobTitle: 'Software Developer',
    employer: 'Edlution Pte Ltd',
    jobDuration: 'July 2020 - April 2021',
    jobLogoImg: EdlutionImg,
    jobLink: 'https://edlution.com.sg',
    jobSummary: "Worked as a software developer in this Singapore based software consultancy company. Contributed in developing backend and frontend for Internet of Things (IoT) servers and web portal dashboards.",
    technologyStacks: [
      SiCsharp,
      SiCplusplus,
      SiPhp,
      SiJavascript,
      SiTypescript,
      SiDotnet,
      SiAngular,
      SiMicrosoftsqlserver,
      SiMysql,
      SiAmazonaws,
      SiGithub,
      SiGit,
    ]
  },
  {
    jobTitle: 'Firmware Engineer',
    employer: 'Lexmark International Inc.',
    jobDuration: 'April 2018 - July 2020',
    jobLogoImg: LexmarkImg,
    jobLink: 'https://www.lexmark.com/en_us.html',
    jobSummary: "Worked as a firmware engineer in this leading manufacturers of laser printers and imaging products. Involved mainly in developing native cloud printing solutions. A number of innovative side projects beneficial to the company were also developed by our team.",
    technologyStacks: [
      SiC,
      SiCplusplus,
      SiPython,
      SiCsharp,
      SiDotnet,
      SiJava,
      SiAndroid,
      SiGerrit,
      SiJenkins,
      SiGit
    ]
  },
  {
    jobTitle: 'Web Development Intern',
    employer: 'Alliance Software Inc.',
    jobDuration: 'April 2017 - June 2017',
    jobLogoImg: AllianceImg,
    jobLink: 'https://www.alliance.com.ph/index.html',
    jobSummary: "Worked as a web development intern tasked to create a web application for the company's internal use.",
    technologyStacks: [
      SiJava,
      SiSpring,
      SiHibernate,
      SiGit
    ]
  },
]

const Experience: React.FC = () => (
  <section id='experience'>
    <div className='bg-stone-400 py-10 sm:py-20 px-2 sm:px-8 md:px-16 xl:px-30'>
      <div className='px-2 sm:px-8 md:px-16 xl:px-40'>
        <h1 className='text-4xl sm:text-5xl text-left font-bold'>EXPERIENCE</h1>
      </div>
      <div className='px-2 sm:px-8 md:px-16 xl:px-40 mt-5'>
        <div className="flex-col">
          {
            workExperiences.map(workExperience => (
              <WorkExperience
                key={workExperience.jobTitle + workExperience.employer}
                jobTitle={workExperience.jobTitle}
                employer={workExperience.employer}
                jobDuration={workExperience.jobDuration}
                jobLink={workExperience.jobLink}
                jobLogoImg={workExperience.jobLogoImg}
                jobSummary={workExperience.jobSummary}
                technologyStacks={workExperience.technologyStacks}
              />
            ))
          }
        </div>
      </div>
    </div>
  </section>
)

export default Experience;
