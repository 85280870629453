import React from 'react';
import About from '../About';
import Contact from '../Contact';
import Experience from '../Experience';
import Footer from '../Footer';
import Header from '../Header';
import Hero from '../Hero';

const Home: React.FC = () => (

  <div>
    <Header />
    <Hero />
    <About />
    <Experience />
    <Contact />
    <Footer />
  </div>
)

export default Home;
