import React from 'react';
import { IconType } from 'react-icons';
import SkillLogoImg from '../SkillLogoImg';

type Props = {
  jobTitle: string;
  employer: string;
  jobDuration: string;
  jobLogoImg: string;
  jobLink: string
  jobSummary: string;
  technologyStacks: IconType[];
}

const WorkExperience: React.FC<Props> = ({ jobTitle, employer, jobDuration, jobLogoImg, jobLink, jobSummary, technologyStacks }) => {
  return (
    <div className='text-left mt-10 border-t-2 border-stone-600'>
      <span className='font-bold text-lg sm:text-xl'>{jobTitle + ' - ' + employer}</span>
      <br />
      <span className='text-xs sm:text-sm'>{jobDuration}</span>
      <div className='flex flex-wrap sm:flex-nowrap justify-center'>
        <div className='flex w-auto sm:w-1/2 m-2 p-2 justify-center'>
          <a target='_blank' href={jobLink} rel='noreferrer'>
            <img
              className='border-2 border-yellow-900 rounded-lg transition duration-500 hover:scale-105 hover:border-yellow-600'
              style={{height:200, width:200}}
              src={jobLogoImg}
              alt={employer}/>
          </a>
        </div>
        <div className='w-auto sm:w-1/2 m-2 p-2 text-left text-sm sm:text-base'>
          <p>
            {jobSummary}
          </p>
          <div className='mt-5'>
            Technology Stack
            <div className='flex flex-wrap p-2'>
              {technologyStacks.map(technologyStack => (
                <SkillLogoImg key={jobTitle + technologyStack} icon={technologyStack} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkExperience;
