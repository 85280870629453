import React from 'react';
import Project from '../Project';

import angularProjects from '../../images/angularprojects.png';
import theMovieCentrale from '../../images/themoviecentrale.png';
import personalWebsite from '../../images/personalwebsite.png';

import azurefundamentals from '../../images/azurefundamentals.png';
import philnits from '../../images/philnits.jpg';

import {
  SiReact,
  SiTailwindcss,
  SiGithub,
  SiGit,
  SiCsharp,
  SiDotnet,
  SiMicrosoftsqlserver,
  SiMicrosoftazure,
  SiAngular,
} from 'react-icons/si';
import Certification from '../Certification';

const personalProjects = [
  {
    title: 'Angular Projects',
    imgSource: angularProjects,
    description: 'A work-in-progress personal project portfolio using Angular Framework. This is created to showcase my knowledge in Angular framework and web development in general.',
    projectLink: 'https://www.angular-projects.louisalbertapas.com/',
    technologyStacks: [
      SiAngular,
      SiTailwindcss,
      SiGithub,
      SiGit
    ]
  },
  {
    title: 'the MOVIE centrale',
    imgSource: theMovieCentrale,
    description: 'A web application using ReactJS and Tailwind CSS. The application can pull movies and TV shows using a third-party API. It fetches the popular ones by default but a search box is provided to allow user input.',
    projectLink: 'https://www.themoviecentrale.louisalbertapas.com/',
    technologyStacks: [
      SiReact,
      SiTailwindcss,
      SiGithub,
      SiGit
    ]
  },
  {
    title: 'My Personal Website',
    imgSource: personalWebsite,
    description: 'My personal website using ReactJS and Tailwind CSS.',
    projectLink: 'https://www.louisalbertapas.com/',
    technologyStacks: [
      SiReact,
      SiTailwindcss,
      SiGithub,
      SiGit
    ]
  },
  {
    title: 'ASP.NET Core Web API Template',
    description: 'An ASP.NET Core Web API Template using Clean Architecture.',
    projectLink: 'https://github.com/louisalbertapas/CleanArchitectureWebAPITemplate',
    technologyStacks: [SiCsharp, SiDotnet, SiMicrosoftsqlserver]
  },
  {
    title: 'Azure Cosmos DB Basic CRUD Operations',
    description: 'A simple proof-of-concept project for basic CRUD operations in Azure Cosmos DB using ASP.NET Core Web API.',
    projectLink: 'https://github.com/louisalbertapas/aspnetcore-web-api-cosmosdb',
    technologyStacks: [SiCsharp, SiDotnet, SiMicrosoftazure]
  },
  {
    title: 'Outbox Pattern Demo',
    description: 'A simple and minimal solution that showcases the concept of Outbox Pattern using .NET Core.',
    projectLink: 'https://github.com/louisalbertapas/OutboxPatternDemo',
    technologyStacks: [SiCsharp, SiDotnet]
  }
]

const certifications = [
  {
    certificationBody: 'Microsoft',
    certificationBodyLink: 'https://www.credly.com/organizations/microsoft-certification/badges',
    certificationTitle: 'Microsoft Certified: Azure Fundamentals',
    certificationLink: 'https://www.credly.com/badges/678025be-7599-4eca-a35b-f5913f69638e/',
    imgSource: azurefundamentals
  },
  {
    certificationBody: 'Philippine National I.T. Standards (PhilNITS)',
    certificationBodyLink: 'https://www.philnits.org/index.html',
    certificationTitle: 'Level 2: Fundamental Information Technology Engineers Passer',
    certificationLink: 'https://itpec.org/statsandresults/all-passers-information/Philippines/2017S_FE.pdf',
    imgSource: philnits
  }
]

const About: React.FC = () => (
  <section id='about'>
    <div className='bg-gradient-to-b from-stone-500 to-stone-400 py-10 sm:py-20 px-2 sm:px-8 md:px-16 xl:px-30 border-t-2 border-stone-600'>
      <div className='px-2 sm:px-8 md:px-16 xl:px-40'>
        <h1 className='text-4xl sm:text-5xl text-left font-bold'>ABOUT ME</h1>
      </div>
      <div className='px-2 sm:px-8 md:px-16 xl:px-40'>
        <p className='pt-4 text-left text-sm sm:text-base'>
          Hi! My name is Louis. I am a passionate, enthusiastic, and detail-oriented senior software developer with more than five years of software development experience specializing in web application development.
        </p>
      </div>
      <div className='px-2 sm:px-8 md:px-16 xl:px-40 mt-5'>
        <h3 className='text-2xl sm:text-3xl text-left'>Skills &amp; Expertise</h3>
        <div className='flex flex-col mt-5'>
          <div className='flex'>
            <div className='w-2/6 font-medium text-sm sm:text-base'>
              Backend Development
            </div>
            <div className="w-4/6 bg-gray-300 rounded-full h-6">
              <div
                className="bg-gradient-to-r from-yellow-800 to-yellow-600 h-6 font-medium text-center p-0.5 pt-1 leading-none rounded-l-full"
                style={{
                  width: "95%",
                  transition: "1s ease",
                  transitionDelay: "0.5s"
                }}>
                95%
              </div>
            </div>
          </div>
          <div className='flex mt-3'>
            <div className='w-2/6 font-medium text-sm sm:text-base'>
              Frontend Development
            </div>
            <div className="w-4/6 bg-gray-300 rounded-full h-6">
              <div
                className="bg-gradient-to-r from-yellow-800 to-yellow-600 h-6 font-medium text-center p-0.5 pt-1 leading-none rounded-l-full"
                style={{
                  width: "88%",
                  transition: "1s ease",
                  transitionDelay: "0.5s"
                }}>
                88%
              </div>
            </div>
          </div>
          <div className='flex mt-3 justify-content'>
            <div className='w-2/6 font-medium text-sm sm:text-base'>
              Web Design
            </div>
            <div className="w-4/6 bg-gray-300 rounded-full h-6">
              <div
                className="bg-gradient-to-r from-yellow-800 to-yellow-600 h-6 font-medium text-center p-0.5 pt-1 leading-none rounded-l-full"
                style={{
                  width: "80%",
                  transition: "1s ease",
                  transitionDelay: "0.5s"
                }}>
                80%
              </div>
            </div>
          </div>
          <div className='flex mt-3 justify-content'>
            <div className='w-2/6 font-medium text-sm sm:text-base'>
              Database Design
            </div>
            <div className="w-4/6 bg-gray-300 rounded-full h-6">
              <div
                className="bg-gradient-to-r from-yellow-800 to-yellow-600 h-6 font-medium text-center p-0.5 pt-1 leading-none rounded-l-full"
                style={{
                  width: "92%",
                  transition: "1s ease",
                  transitionDelay: "0.5s"
                }}>
                92%
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-2 sm:px-8 md:px-16 xl:px-40 mt-20">
        <h3 className='text-2xl sm:text-3xl text-left'>Certifications</h3>
        <div className='grid grid-cols-1 sm:grid-cols-3'>
          {
            certifications.map(certification => (
              <Certification
                key={certification.certificationTitle}
                certificationBody={certification.certificationBody}
                certificationBodyLink={certification.certificationBodyLink}
                certificationTitle={certification.certificationTitle}
                certificationLink={certification.certificationLink}
                imgSource={certification.imgSource} />
            ))
          }
        </div></div>
      <div className='px-2 sm:px-8 md:px-16 xl:px-40 mt-20'>
        <h3 className='text-2xl sm:text-3xl text-left'>Personal Projects</h3>
        <div className='flex-col '>
          {
            personalProjects.map(project => (
              <Project
                key={project.title}
                title={project.title}
                imgSource={project.imgSource}
                description={project.description}
                projectLink={project.projectLink}
                technologyStacks={project.technologyStacks} />
            ))
          }
        </div>
      </div>
    </div>
  </section>
)

export default About;
