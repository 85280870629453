import React from 'react';
import { IconType } from 'react-icons';
import { FiExternalLink } from 'react-icons/fi';
import SkillLogoImg from '../SkillLogoImg';

type Props = {
  title: string;
  imgSource: string | undefined;
  description: string;
  projectLink?: string | undefined;
  technologyStacks: IconType[];
};

const Project: React.FC<Props> = ({ title, imgSource, description, projectLink, technologyStacks }) => {
  return (
    <div className='text-left mt-5'>
      <div className='border-t-2 border-stone-600'>
        <a
          href={projectLink}
          target='_blank'
          rel='noreferrer'>
          <span className='text-lg sm:text-xl font-bold hover:text-yellow-900 hover:underline transition duration-500'>{title} <FiExternalLink className='inline' /></span>
        </a>
      </div>
      <div className='flex flex-wrap sm:flex-nowrap'>
        <div className='flex w-auto sm:w-1/2 m-2 p-2 justify-center'>
          <a
            href={projectLink}
            target='_blank'
            rel='noreferrer'>
            { imgSource !== undefined &&
              <img
                className='border-2 border-yellow-900 rounded-lg transition duration-500 hover:scale-105 hover:border-yellow-600'
                src={imgSource}
                alt={title} />
            }
          </a>
        </div>
        <div className='w-auto sm:w-1/2 m-2 p-2 text-left text-sm sm:text-base'>
          {description}
          <div className='mt-5'>
            Technology Stack
            <div className='flex flex-wrap p-2'>
              {technologyStacks.map(technologyStack => (
                <SkillLogoImg key={title + technologyStack} icon={technologyStack} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Project;
