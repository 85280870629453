import React from 'react'

const Footer: React.FC = () => (
  <div className='bg-gradient-to-t from-yellow-900 to-stone-600'>
    <p className='py-1 text-white text-center text-xs'>
      Copyright 2024 © Louis Albert Apas
    </p>

  </div>
)

export default Footer;
