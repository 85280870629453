import React from 'react';

// Icons
import { FiMail, FiLinkedin, FiGithub, FiFacebook, FiInstagram } from 'react-icons/fi';

// Components
import SocialLink from '../SocialLink';

const Contact: React.FC = () => (
  <section id='contact'>
    <div className='bg-gradient-to-b from-stone-400 to-stone-500 py-10 sm:py-20 px-2 sm:px-8 md:px-16 xl:px-30 border-b-2 border-stone-600'>
      <div className='px-2 sm:px-8 md:px-16 xl:px-40'>
        <h1 className='text-4xl sm:text-5xl text-left font-bold'>CONTACT INFORMATION</h1>
      </div>
      <div className='px-2 sm:px-8 md:px-16 xl:px-40'>
        <p className='pt-4 text-left text-sm sm:text-base'>
          For custom software development and further information, you can reach me through the following channels:
        </p>
        <div className='flex-col text-left px-5 text-sm sm:text-base'>
          <div className='mt-2'>
            <SocialLink link='mailto:louisalbertapas25@gmail.com'>
              <FiMail className='h-6 w-6' style={{ position: "relative", display: "inline" }} /> Louis Albert Apas
            </SocialLink>
          </div>
          <div className='mt-2'>
            <SocialLink link='https://www.linkedin.com/in/louisalbertapas'>
              <FiLinkedin className='h-6 w-6' style={{ position: "relative", display: "inline" }} /> Louis Albert Apas
            </SocialLink>
          </div>
          <div className='mt-2'>
            <SocialLink link='https://github.com/louisalbertapas'>
              <FiGithub className='h-6 w-6' style={{ position: "relative", display: "inline" }} /> louisalbertapas
            </SocialLink>
          </div>
          <div className='mt-2'>
            <SocialLink link='https://www.facebook.com/louisalbertapas'>
              <FiFacebook className='h-6 w-6' style={{ position: "relative", display: "inline" }} /> louisalbertapas
            </SocialLink>
          </div>
          <div className='mt-2'>
            <SocialLink link='https://www.instagram.com/louisalbertapas1'>
              <FiInstagram className='h-6 w-6' style={{ position: "relative", display: "inline" }} /> louisalbertapas1
            </SocialLink>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Contact;
