import React from 'react'
import { IconType } from 'react-icons';

type Props = {
  icon: IconType;
};

const SkillLogoImg: React.FC<Props> = ({ icon }) => (
  <div className='px-1 mt-1'>
    {
      React.createElement(icon, {
        className: "h-8 w-8 md:h-12 md:w-12 m-1 transition duration-500 hover:scale-125"
      })
    }
  </div>
)

export default SkillLogoImg;