import React from 'react';

type Props = {
  certificationBody: string;
  certificationBodyLink: string;
  certificationTitle: string;
  certificationLink: string;
  imgSource: string
};

const Certification: React.FC<Props> = ({certificationBody, certificationBodyLink, certificationTitle, certificationLink, imgSource}) => {
  return (
    <div className='text-left mt-5'>
      <div className='flex flex-wrap sm:flex-nowrap'>
        <div className='flex flex-col w-auto m-2 p-2 justify-center'>
          <a
            href={certificationLink}
            target='_blank'
            rel='noreferrer'>
            <img
              className='rounded-lg transition duration-500 hover:scale-105 hover:border-yellow-600'
              style={{height:200, width:200}}
              src={imgSource}
              alt={certificationBody} />
          </a>
          <div className="font-bold mt-2">
            <a href={certificationBodyLink}
              target='_blank'
              rel='noreferrer'>
              {certificationBody}
            </a>
          </div>
          <div className="mt-2">
            {certificationTitle}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Certification;
